import {useParams} from "react-router";
import * as React from "react";
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import Loading from "../../components/loading";
import useDebounce from "../../lib/UseDebounce";
import {
    Alert,
    Box,
    Button,
    FormControl, FormControlLabel,
    FormGroup, IconButton,
    Input,
    InputAdornment,
    Link, Menu, MenuItem,
    Paper, Switch, Table, TableBody,
    TableCell,
    TableContainer, TableHead
} from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SearchIcon from "@mui/icons-material/Search";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ClickWallSchoolUpsert from "./clickWallSchoolUpsert";
import ClickWallProgramUpsert from "./clickWallProgramUpsert";
import {toast} from "react-toastify";

export default function ClickWallProgramMapsPage() {
    let { school_id, program_id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [school, setSchool] = React.useState(null as any);
    const [program, setProgram] = React.useState(null as any);
    const [maps, setMaps] = React.useState([] as any[]);
    const [search, setSearch] = React.useState('');
    const [selected, setSelected] = React.useState(false);
    const [associate, setAssociate] = React.useState(true);
    const [bachelor, setBachelor] = React.useState(true);
    const [master, setMaster] = React.useState(true);
    const [doctorate, setDoctorate] = React.useState(true);
    const [certificate, setCertificate] = React.useState(true);
    const [graduate, setGraduate] = React.useState(true);
    const [exact, setExact] = React.useState([] as any);
    const [similar, setSimilar] = React.useState([] as any);
    const getData = async () => {
        const q = gql`
            query q ($criteria: ClickWallProgramCriteria!, $criteria2: ClickWallSchoolCriteria!, $programId: Int!) {
                clickWallPrograms(criteria: $criteria) {
                    id
                    name
                }
                clickWallSchools(criteria: $criteria2) {
                    id
                    name
                }
                clickWallProgramMappings(program_id: $programId) {
                    d
                    f
                    m
                    id
                    s
                    w
                }                
            }`;

        const variables = {
            criteria: {
                school_id: parseInt(school_id || '0'),
                id: parseInt(program_id || '0'),
            },
            criteria2: {
                id: parseInt(school_id || '0')
            },
            programId: parseInt(program_id || '0')
        };

        return await query(q, variables);
    }

    const reload = async (sSearch: string) => {
        setLoading(true);
        const data = await getData();
        setSchool(data.clickWallSchools[0]);
        setProgram(data.clickWallPrograms[0]);
        setMaps(data.clickWallProgramMappings);

        data.clickWallProgramMappings.forEach((x: any) => {
            if (x.s) {
                exact.push(x.id);
            }
            if (x.w) {
                similar.push(x.id);
            }
        });

        setExact(exact);

        const programName = data.clickWallPrograms[0].name;
        document.title = `Click Wall Programs: ${data.clickWallSchools[0].name} > ${programName} | DN`;

        if (programName.includes("Doctor")
            || programName.includes("PHD")
            || programName.includes("PhD")
            || programName.includes("DNP")
            || programName.includes("PSYD")
            || programName.includes("DPSY")
            || programName.includes("EDD")
            || programName.includes("DMFT")
            || programName.includes("DBA")
            || programName.includes("DCJ")
        ) {
            setDoctorate(true);
            setBachelor(false);
            setMaster(false);
            setCertificate(false);
            setGraduate(false);
            setAssociate(false);
        } else if (
            programName.includes("MBA")
            || programName.includes("MS")
            || programName.includes("M.S")
            || programName.includes("MA")
            || programName.includes("M.A")
            || programName.includes("Master")
            || programName.includes("MAMFT")
            || programName.includes("MED")
            || programName.includes("MPSY")
            || programName.includes("MSOL")
        ) {
            setDoctorate(false);
            setBachelor(false);
            setMaster(true);
            setCertificate(false);
            setGraduate(true);
            setAssociate(false);
        } else if (
            programName.includes("B.S")
            || programName.includes("B.A")
            || programName.includes("BS")
            || programName.includes("BA")
            || programName.includes("Bachelor")
        ) {
            setDoctorate(false);
            setBachelor(true);
            setMaster(false);
            setCertificate(false);
            setGraduate(false);
            setAssociate(true);
        }



        setLoading(false);
    }

    const updateExact = async (id: number, value: boolean) => {
        if (value) {
            if (similar.includes(id)) {
                toast.error('Cannot be both exact and similar');
                return;
            }
            setExact([...exact, id]);
        } else {
            setExact(exact.filter((x: number) => x !== id));
        }

        const g = gql`
            mutation m($input: ClickWallProgramMapInput!) {
                clickWallProgramMapUpdate(input: $input)
            }
        `;
        const variables = {
            input: {
                program_id: parseInt(program_id || '0'),
                major_id: id,
                is_wir: false,
                value
            }
        };

        await mutate(g, variables);
    }

    const updateSimilar = async (id: number, value: boolean) => {
        if (value) {
            if (exact.includes(id)) {
                toast.error('Cannot be both exact and similar');
                return;
            }
            setSimilar([...similar, id]);
        } else {
            setSimilar(similar.filter((x: number) => x !== id));
        }

        const g = gql`
            mutation m($input: ClickWallProgramMapInput!) {
                clickWallProgramMapUpdate(input: $input)
            }
        `;
        const variables = {
            input: {
                program_id: parseInt(program_id || '0'),
                major_id: id,
                is_wir: true,
                value
            }
        };

        await mutate(g, variables);
    }

    React.useEffect(() => {
        reload('');
    }, []);

    if (!program) {
        return <> </>;
    }

    return (
        <>
            <h2>CW Map: {school.name} {">"} {program.name}
                <Button component={Link} variant="outlined" size="small" sx={{margin: '0 5px'}}
                        href={`/clickWallPrograms/${school_id}`}>&larr; Back</Button>
            </h2>
            <FormGroup row={true}>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => setSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>

                <FormControlLabel control={<Switch checked={selected} onChange={(event)=> setSelected(event.target.checked)} />} label="Selected Only" />
                <FormControlLabel control={<Switch checked={associate} onChange={(event)=> setAssociate(event.target.checked)} />} label="Associate" />
                <FormControlLabel control={<Switch checked={bachelor} onChange={(event)=> setBachelor(event.target.checked)} />} label="Bachelor" />
                <FormControlLabel control={<Switch checked={master} onChange={(event)=> setMaster(event.target.checked)} />} label="Master" />
                <FormControlLabel control={<Switch checked={doctorate} onChange={(event)=> setDoctorate(event.target.checked)} />} label="Doctorate" />
                <FormControlLabel control={<Switch checked={certificate} onChange={(event)=> setCertificate(event.target.checked)} />} label="Certificate" />
                <FormControlLabel control={<Switch checked={graduate} onChange={(event)=> setGraduate(event.target.checked)} />} label="Graduate" />
            </FormGroup>
            {loading ? <Loading/> : <>
                <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 200px)'}}>
                    <Table size="small">
                        <TableHead>
                            <TableHeaderRow>
                                <TableCell>
                                    Program
                                </TableCell>
                                <TableCell>
                                    Exact
                                </TableCell>
                                <TableCell>
                                    Similar
                                </TableCell>
                            </TableHeaderRow>
                        </TableHead>
                        <TableBody>
                        {maps.filter(x=>!search.trim() || (x.d + x.f + x.m).toLowerCase().includes(search.trim().toLowerCase()))
                            .filter(x=>!selected || exact.includes(x.id) || similar.includes(x.id))
                            .filter(x=> x.d !== "Associate's" || associate)
                            .filter(x=> x.d !== "Bachelor's" || bachelor)
                            .filter(x=> x.d !== "Master's" || master)
                            .filter(x=> x.d !== "Doctorate" || doctorate)
                            .filter(x=> x.d !== "Certificate / Diploma" || certificate)
                            .filter(x=> x.d !== "Graduate Certificates" || graduate)
                            .map((row: any, index: number) => (
                            <StripedTableRow key={index}>
                                <TableCell><>{row.d} {">"} {row.f} {">"} {row.m}</></TableCell>
                                <TableCell><Switch checked={exact.includes(row.id)} onChange={(event)=> updateExact(row.id, event.target.checked)} /></TableCell>
                                <TableCell><Switch checked={similar.includes(row.id)} onChange={(event)=> updateSimilar(row.id, event.target.checked)} /></TableCell>
                            </StripedTableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </>}
        </>
    );
}
