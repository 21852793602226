import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import {useEffect} from "react";
import {Navigate, Outlet, useLocation} from "react-router";
import Box from "@mui/material/Box";
import {useAuth} from "../providers/auth";
import {useIdleTimer} from "react-idle-timer";
import {
    AppBar, Badge,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Typography from "@mui/material/Typography";
import {PowerOff} from "@mui/icons-material";
import logo from "../assets/logo.png";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiTreeItem from '@mui/lab/TreeItem';
import {PermissionType} from "../objects/UserRead";
import {Link} from "react-router-dom";
import {gql} from "@apollo/client";
import {query} from "../lib/apollo";
import {getCookie} from "../lib/cookies";
import NotificationsIcon from '@mui/icons-material/Notifications';

const drawerWidth = 240;
const TreeItem = styled(MuiTreeItem)({
    '& .MuiTreeItem-content': {
        '& .MuiTreeItem-label': {
            lineHeight: 2.0
        }
    }
});


export default function Secure() {
    let auth = useAuth();
    let location = useLocation();
    const [failedEvents, setFailedEvents] = React.useState<number>(0);

    async function checkFailedEvents() {
        if (auth.user?.permissions.includes(PermissionType.FailedEvents)) {
            const failedEvents = await getFailedEventsCount();
            setFailedEvents(failedEvents);
        }
        const handle = setInterval(async () => {
            if (!auth.user || !getCookie("session_id")) {
                clearInterval(handle);
                return;
            }
            if (auth.user?.permissions.includes(PermissionType.FailedEvents)) {
                const failedEvents = await getFailedEventsCount();
                setFailedEvents(failedEvents);
            }
        }, 1000 * 60);
    }

    function stillLoggedInSetup() {
        const handle = setInterval(async () => {
            const isGood = await auth.self();
            if (!isGood) {
                clearInterval(handle);
            }
        }, 1000 * 60);
    }


    const onIdle = () => {
        auth.signOut(true);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useIdleTimer({
        onIdle,
        timeout: 1000 * 60 * 60 * 5,
        throttle: 500
    })
    const [mobileOpen, setMobileOpen] = React.useState(false);


    useEffect(() => {
        stillLoggedInSetup();
        checkFailedEvents().then(() => {});
    }, []);

    if (!auth.user) {
        return <Navigate to="/" state={{ from: auth.saveFrom ? location: null }} />;
    }

    let uri = process.env.REACT_APP_OLD_URL;

    if (window.location.hostname.includes("gitpod")) {
        uri = "https://www2.degreenexus.com/admin";
    }

    // `${uri}/new/ui/index.php#/dashboard`
    const menu = [
        {label: "Dashboard", path: "/dashboard"},
        {label: "Reporting", path: "/reporting", permissions: [PermissionType.HPCampaigns, PermissionType.Revenue, PermissionType.Admin, PermissionType.ConversionStats, PermissionType.ConversionStatsAdmin], children: [
                {label: "Campaigns", path: "/campaigns", permissions: [PermissionType.HPCampaigns], url: `${uri}/new/ui/index.php#/campaigns`},
                {label: "Revenue Calendar", path: "/revenueCalendar", permissions: [PermissionType.Admin]},
                {label: "Conversion Stats", path: "/conversionStats", permissions: [PermissionType.ConversionStats, PermissionType.ConversionStatsAdmin], url: `${uri}/new/ui/index.php#/conversion_stats`},
                {label: "Conversion Stats Linkout", path: "/conversionStatsLinkout", permissions: [PermissionType.Admin], url: `${uri}/new/ui/index.php#/conversion_stats_lo`},
                {label: "Inventory Stats", path: "/inventory", permissions: [PermissionType.Inventory, PermissionType.Admin], url: `${uri}/new/ui/index.php#/inventory`},
                {label: "Billables", path: "/billables", permissions: [PermissionType.Billables], url: `${uri}/new/ui/index.php#/billables`},
                {label: "Report Cards", path: "/reportCards", permissions: [PermissionType.Admin]},
            ]},
        {label: "Portal Redirect", path: "/portalRedirect", permissions: [PermissionType.PR, PermissionType.Admin, PermissionType.FailedEvents, PermissionType.CallCenter, PermissionType.Clickwall], children: [
                {label: "Campaigns", path: "/portalRedirectCampaigns", permissions: [PermissionType.PR], url: `${uri}/new/ui/index.php#/pr`},
                {label: "Ping Logs", path: "/portalRedirectPingLogs", permissions: [PermissionType.Admin], url: `https://prlogs.degreenexus.com/pings`},
                {label: "Stories", path: "/portalRedirectStories", permissions: [PermissionType.Admin], url: `https://prlogs.degreenexus.com/`},
                {label: "Click Wall", path: "/clickWallSchools", permissions: [PermissionType.Admin, PermissionType.Clickwall]},
                {label: "Call Center Leaderboard", path: "/clickWallLeaderboard", permissions: [PermissionType.Admin, PermissionType.CallCenter]},
            ]},
        {label: "Click Feed", path: "/clickFeed", permissions: [PermissionType.CF, PermissionType.CFRead], children: [
                {label: "Campaigns", path: "/clickFeedCampaigns", permissions: [PermissionType.CF, PermissionType.CFRead], url: `${uri}/new/ui/index.php#/cf`},
                {label: "Imported Stats", path: "/clickFeedImported", permissions: [PermissionType.CF]},
                {label: "CF Stats", path: "/clickFeedStats", permissions: [PermissionType.CF], url: `${uri}/new/ui/index.php#/cfstats`},
            ]},
        {label: "Leads", path: "/leads", permissions: [PermissionType.Admin, PermissionType.LookupLead], children: [
                {label: "Lookup Lead", path: "/lead", permissions: [PermissionType.LookupLead], url: `${uri}/lead_display.php`},
                {label: "Upload Conversions", path: "/uploadConversions", permissions: [PermissionType.Admin], url: `${uri}/mark_conversions.php`},
                {label: "Upload Conversions LO", path: "/uploadConversionsLO", permissions: [PermissionType.Admin], url: `${uri}/mark_conversions_lo.php`},
                {label: "Google Stats Upload", path: "/google", permissions: [PermissionType.Admin], url: `${uri}/new/ui/index.php#/google`},
                {label: "Gclid Revenue", path: "/gclidRevenue", permissions: [PermissionType.Admin]},
                {label: "IP Lookup", path: "/ipLookup", permissions: [PermissionType.Admin]},
                {label: "OA Lookup", path: "/oa", permissions: [PermissionType.Admin], url: `${uri}/new/ui/index.php#/colors`},
                {label: "Melissa Zip Update", path: "/zipUpload", permissions: [PermissionType.Admin]},
                {label: "Unsubscribe", path: "/unsubscribe", permissions: [PermissionType.Admin], url: `${uri}/unsubscribe.php`},
                {label: "Publisher Credits", path: "/publisherCredits", permissions: [PermissionType.Admin]},
            ]},
        {label: "Users", path: "/usersHub", permissions: [PermissionType.Admin], children: [
                {label: "Brokers", path: "/brokers", permissions: [PermissionType.Admin]},
                {label: "Companies", path: "/companies", permissions: [PermissionType.Admin]},
                {label: "Schools", path: "/schools", permissions: [PermissionType.Admin]},
                {label: "Publishers", path: "/publishers", permissions: [PermissionType.Admin]},
                {label: "Users", path: "/users", permissions: [PermissionType.Admin]},
                {label: "API Usage", path: "/apiUsage", permissions: [PermissionType.Admin]},
            ]},
        {label: "Campaigns", path: "/campaigns", permissions: [PermissionType.Admin, PermissionType.GroupCaps, PermissionType.HPCampaignsModify, PermissionType.FailedEvents], children: [
                {label: "Create Campaign", path: "/createCampaign", permissions: [PermissionType.HPCampaignsModify], url: `${uri}/hp_campaign_update.php?hp_campaign_id=0`},
                {label: "Create Event", path: "/createEvent", permissions: [PermissionType.HPCampaignsModify], url: `${uri}/hp_event.php`},
                {label: "Program Sets", path: "/programSets", permissions: [PermissionType.HPCampaignsModify], url: `${uri}/hp_program_sets.php`},
                {label: "Rules", path: "/rules", permissions: [PermissionType.FailedEvents]},
                {label: "Set Caps", path: "/setCaps", permissions: [PermissionType.HPCampaignsModify], url: `${uri}/hp_update_caps.php`},
                {label: "Group Caps", path: "/groupCaps", permissions: [PermissionType.GroupCaps], url: `${uri}/new/ui/index.php#/group_caps`},
                {label: "Blocked Publishers", path: "/blockedPubs", permissions: [PermissionType.Admin]},
            ]},
        {label: "SEO", path: "/seo", permissions: [PermissionType.SEO, PermissionType.SEOData], children: [
                {label: "Schools", path: "/seoSchools", permissions: [PermissionType.SEO, PermissionType.SEOData]},
            ]},
    ]

    const StyledLink = styled(Link)`
    text-decoration: none;
      color: inherit;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
    // generate menu recursively
    const generateMenu = (menu: any) => {
        return menu.map((item: any) => {
            if (item.permissions && !item.permissions.filter((x: PermissionType) => auth.user?.permissions.includes(x)).length) {
                return null;
            }

            if (item.children) {
                return (
                    <TreeItem key={item.path} nodeId={item.path} label={item.label}>
                        {generateMenu(item.children)}
                    </TreeItem>
                )
            } else {
                return (
                    <React.Fragment key={item.path}>
                        {item.url && <StyledLink to={item.url}><TreeItem nodeId={item.path} label={item.label} onClick={handleDrawerToggle}/></StyledLink>}
                        {!item.url && <StyledLink to={item.path}><TreeItem nodeId={item.path} label={item.label} onClick={handleDrawerToggle}/></StyledLink>}
                    </React.Fragment>
                )
            }
        })
    }

    const drawer = (
        <div>
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ flexGrow: 1, overflowY: 'auto', py: 2 }}
            >
                {generateMenu(menu)}
            </TreeView>
            <Divider />
            <List>
                <ListItem key="LogOff" disablePadding onClick={() => auth.signOut(false)}>
                    <ListItemButton>
                        <ListItemIcon>
                            <PowerOff/>
                        </ListItemIcon>
                        <ListItemText primary="Log Off" />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = window.document.body;
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'block' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <IconButton aria-label="Logo" to={"/dashboard"} component={Link}>
                        <img src={logo} alt="Logo"/>
                    </IconButton>

                    {failedEvents > 0 &&
                    <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                        component={Link}
                        to={`${uri}/new/ui/index.php#/prbad`}
                    >
                        <Badge badgeContent={failedEvents} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    }

                    <Typography variant="h6" noWrap component="div" style={{marginLeft: 'auto'}}>
                        {auth.user.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, px: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Outlet/>
            </Box>
        </Box>
    );
}

const getFailedEventsCount = async () => {
    const q =
    gql`
        #NO_NETWORK_ERROR
        query {
            ruleFails {
                lead_id
            }
        }
    `;

    const response = await query(q, null);

    return response?.ruleFails?.length || 0;
}
